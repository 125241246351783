import {IAddress, Address} from 'app/blocks/model/address.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IProspectingInfo extends IBaseEntity {
    id?: number;
    isProspecting?: boolean;
    estimatedSupply?: number;
    dateOfLastContact?: any;
    dateOfNextContact?: any;
    stageOfPipeline?: string;
    chanceOfWinning?: string;
    expectedDateOfWinning?: any;
}

export class ProspectingInfo extends BaseEntity implements IProspectingInfo {
    id?: number;
    isProspecting?: boolean;
    estimatedSupply?: number;
    dateOfLastContact?: any;
    dateOfNextContact?: any;
    stageOfPipeline?: string;
    chanceOfWinning?: string;
    expectedDateOfWinning?: any;

    constructor(prospectingInfo?) {
        super();
        if (prospectingInfo) {
            this.id = prospectingInfo.id;
            this.isProspecting = prospectingInfo.isProspecting;
            this.estimatedSupply = prospectingInfo.estimatedSupply;
            this.dateOfLastContact = prospectingInfo.dateOfLastContact;
            this.dateOfNextContact = prospectingInfo.dateOfNextContact;
            this.stageOfPipeline = prospectingInfo.stageOfPipeline;
            this.chanceOfWinning = prospectingInfo.chanceOfWinning;
            this.expectedDateOfWinning = prospectingInfo.expectedDateOfWinning;
        } else {
            this.isProspecting = false;
        }
    }

    clone(): ProspectingInfo {
        return new ProspectingInfo(this);
    }
}
