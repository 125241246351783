import {ProductList} from 'app/blocks/model/product-list.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IBranch} from 'app/blocks/model/branch.model';
import {ContactInfo, IContactInfo} from 'app/blocks/model/contact-info.model';
import {ICreditClass} from 'app/blocks/model/credit-class.model';
import {ICustomerGroup} from 'app/blocks/model/customer-group.model';
import {ICustomerPriceList} from 'app/blocks/model/customer-price-list.model';
import {ICustomerStatusReason} from 'app/blocks/model/customer-status-reason.model';
import {ICustomerStatus} from 'app/blocks/model/customer-status.model';
import {IDeliveryPoint, DeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IMajorAccountCode} from 'app/blocks/model/major-account-code.model';
import {INote, Note} from 'app/blocks/model/note.model';
import {IPaymentType} from 'app/blocks/model/payment-type.model';
import {IServicePriorityCode} from 'app/blocks/model/service-priority-code.model';
import {IStatementCode} from 'app/blocks/model/statement-code.model';
import {PriceList} from 'app/blocks/model/price-list.model';
import {IBank} from 'app/blocks/model/bank.model';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IFranchise} from 'app/blocks/model/franchise.model';
import {IPartnerCompany} from 'app/blocks/model/partner-company.model';

export interface ICustomer extends IBaseFranchiseEntity {
    id?: number;
    name?: string;
    accountNo?: string;
    secondaryAccountNo?: string;
    externalAccountReference?: string;
    startDate?: any;
    creditLimit?: number;
    vatRegNo?: string;
    inactive?: boolean;
    contactInfo?: IContactInfo;
    notes?: INote;
    homeBranch?: IBranch;
    customerStatus?: ICustomerStatus;
    startReason?: ICustomerStatusReason;
    accountManager?: IEmployee;
    salesRepresentative?: IEmployee;
    servicePriorityCode?: IServicePriorityCode;
    statementCode?: IStatementCode;
    preferredPaymentType?: IPaymentType;
    majorAccountCode?: IMajorAccountCode;
    customerPriceList?: PriceList;
    generalPriceList?: PriceList;
    creditController?: IEmployee;
    creditClass?: ICreditClass;
    deliveryPoints?: IDeliveryPoint[];
    customerGroups?: ICustomerGroup[];
    customerPriceLists?: ICustomerPriceList[];
    defaultProducts?: IBaseEntity[];
    bankAccounts?: IBank[];
    balance?: number;
    productWhitelist?: ProductList;
    productBlacklist?: ProductList;
    underReview?: boolean;
    vatNotApplicable?: boolean;
    productCodeMappings?: any[];
    productPromotions?: any[];
    customerSpecificDiscount?: number;
    uuid?: string;
    currency?: any;
    customerAccountType?: any;
    masterCustomer?: ICustomer;
    poMandatoryForInvoice?: boolean;
    autoUpdateDefaultProducts?: boolean;
    partnerCompany?: IPartnerCompany;
    customerBalance?: any;
    autoUpdatePromotionProducts?: boolean;
    autoApplyCreditNotes?: boolean;
}
export class Customer extends BaseFranchiseEntity implements ICustomer {
    public id?: number;
    public name?: string;
    public accountNo?: string;
    public secondaryAccountNo?: string;
    public externalAccountReference?: string;
    public startDate?: any;
    public creditLimit?: number;
    public vatRegNo?: string;
    public inactive?: boolean;
    public contactInfo?: IContactInfo;
    public notes?: INote;
    public homeBranch?: IBranch;
    public customerStatus?: ICustomerStatus;
    public startReason?: ICustomerStatusReason;
    public accountManager?: IEmployee;
    public salesRepresentative?: IEmployee;
    public servicePriorityCode?: IServicePriorityCode;
    public statementCode?: IStatementCode;
    public preferredPaymentType?: IPaymentType;
    public majorAccountCode?: IMajorAccountCode;
    public customerPriceList?: PriceList;
    public generalPriceList?: PriceList;
    public creditController?: IEmployee;
    public creditClass?: ICreditClass;
    public deliveryPoints?: IDeliveryPoint[];
    public customerGroups?: ICustomerGroup[];
    public customerPriceLists?: ICustomerPriceList[];
    public defaultProducts?: IBaseEntity[];
    public bankAccounts?: IBank[];
    public balance?: number;
    public productWhitelist?: ProductList;
    public productBlacklist?: ProductList;
    public underReview?: boolean;
    public vatNotApplicable?: boolean;
    public productCodeMappings?: any[];
    public productPromotions?: any[];
    public productPromotionIds?: any[];
    public customerSpecificDiscount?: number;
    public editable: boolean;
    public uuid?: string;
    public currency?: any;
    public customerAccountType?: any;
    public masterCustomer?: ICustomer;
    public poMandatoryForInvoice?: boolean;
    public autoUpdateDefaultProducts?: boolean;
    public partnerCompany?: IPartnerCompany;
    public customerBalance?: any;
    public autoUpdatePromotionProducts?: boolean;
    public autoApplyCreditNotes?: boolean;

    constructor(customer?) {
        super();
        if (customer) {
            this.id = customer.id;
            this.name = customer.name;
            this.accountNo = customer.accountNo;
            this.secondaryAccountNo = customer.secondaryAccountNo;
            this.externalAccountReference = customer.externalAccountReference;
            this.startDate = MposDate.newInstance(customer.startDate);
            this.creditLimit = customer.creditLimit;
            this.vatRegNo = customer.vatRegNo;
            this.inactive = customer.inactive;
            this.homeBranch = customer.homeBranch;
            this.customerStatus = customer.customerStatus;
            this.startReason = customer.startReason;
            this.accountManager = customer.accountManager;
            this.salesRepresentative = customer.salesRepresentative;
            this.servicePriorityCode = customer.servicePriorityCode;
            this.statementCode = customer.statementCode;
            this.preferredPaymentType = customer.preferredPaymentType;
            this.majorAccountCode = customer.majorAccountCode;
            this.customerPriceList = customer.customerPriceList;
            this.generalPriceList = customer.generalPriceList;
            this.productWhitelist = customer.productWhitelist;
            this.productBlacklist = customer.productBlacklist;
            this.creditController = customer.creditController;
            this.creditClass = customer.creditClass;
            this.bankAccounts = customer.bankAccounts || [];

            this.contactInfo = new ContactInfo(customer.contactInfo);
            this.notes = new Note(customer.notes);
            this.deliveryPoints = customer.deliveryPoints ? DeliveryPoint.processArray(customer.deliveryPoints) : [];
            this.customerGroups = customer.customerGroups || [];
            this.customerPriceLists = customer.customerPriceLists || [];
            this.defaultProducts = customer.defaultProducts || [];
            this.balance = customer.balance;
            this.underReview = customer.underReview;
            this.vatNotApplicable = customer.vatNotApplicable;
            this.productCodeMappings = customer.productCodeMappings || [];
            this.productPromotions = customer.productPromotions || [];
            this.productPromotionIds = customer.productPromotionIds || [];
            this.customerSpecificDiscount = customer.customerSpecificDiscount;
            this.editable = customer.editable;
            this.owningFranchise = customer.owningFranchise;
            this.uuid = customer.uuid;
            this.currency = customer.currency;
            this.customerAccountType = customer.customerAccountType;
            this.masterCustomer = customer.masterCustomer;
            this.poMandatoryForInvoice = customer.poMandatoryForInvoice;
            this.autoUpdateDefaultProducts = customer.autoUpdateDefaultProducts;
            this.partnerCompany = customer.partnerCompany;
            this.customerBalance = customer.customerBalance?.balance;
            this.autoUpdatePromotionProducts = customer.autoUpdatePromotionProducts;
            this.autoApplyCreditNotes = customer.autoApplyCreditNotes;
        } else {
            this.contactInfo = new ContactInfo();
            this.notes = new Note();
            this.deliveryPoints = [];
            this.customerGroups = [];
            this.customerPriceLists = [];
            this.defaultProducts = [];
            this.productCodeMappings = [];
            this.startDate = MposDate.currentDate();
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): Customer {
        return new Customer(this);
    }
}
