import {ICountry, Country} from 'app/blocks/model/country.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IAddress extends IBaseEntity {
    id: number;
    line1: string;
    line2: string;
    townCity: string;
    countyState: string;
    postCodeZip: string;
    latitude: number;
    longitude: number;
    country: ICountry;
}

export class Address extends BaseEntity implements IAddress {
    id: number;
    line1: string;
    line2: string;
    townCity: string;
    countyState: string;
    postCodeZip: string;
    latitude: number;
    longitude: number;
    country: ICountry;

    constructor(address?) {
        super();
        if (address) {
            this.id = address.id;
            this.line1 = address.line1;
            this.line2 = address.line2;
            this.townCity = address.townCity;
            this.countyState = address.countyState;
            this.postCodeZip = address.postCodeZip;
            this.latitude = address.latitude;
            this.longitude = address.longitude;
            this.country = address.country;
        } else {
            //this.country = new Country();
        }
    }

    clone(): Address {
        return new Address(this);
    }
}
