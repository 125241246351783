import {IPlanogramItem} from 'app/blocks/model/planogram-item.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ContactInfo, IContactInfo} from 'app/blocks/model/contact-info.model';
import {IDeliveryPointMessage} from 'app/blocks/model/delivery-point-message.model';
import {IDeliveryPointSchedule} from 'app/blocks/model/delivery-point-schedule.model';
import {IDepositItemInfo} from 'app/blocks/model/deposit-item-info.model';
import {IDynamicHeaderCode} from 'app/blocks/model/dynamic-header-code.model';
import {INote, Note} from 'app/blocks/model/note.model';
import {IProductList, ProductList} from 'app/blocks/model/product-list.model';
import {IRoute} from 'app/blocks/model/route.model';
import {IStopType} from 'app/blocks/model/stop-type.model';
import {ITimeWindow} from 'app/blocks/model/time-window.model';
import {IDeliveryPointSetting} from 'app/blocks/model/delivery-point-setting.model';
import {IStandingOrder} from 'app/blocks/model/standing-order.model';
import {TimeWindow} from 'app/blocks/model/time-window.model';
import {IPriceList} from 'app/blocks/model/price-list.model';
import {DateWindow, IDateWindow} from 'app/blocks/model/date-window.model';
import {IDeliveryPointDailySequence} from 'app/blocks/model/dp-daily-schedule';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IProspectingInfo, ProspectingInfo} from 'app/blocks/model/prospecting-info.model';
import {IEmployee} from 'app/blocks/model/employee.model';

export interface IDeliveryPoint extends IBaseFranchiseEntity {
    id?: number;
    active?: boolean;
    accountName?: string;
    accountNumber?: string;
    sequence?: number;
    typicalServiceTime?: number;
    physicalKeyNumber?: string;
    contactInfo?: IContactInfo;
    notes?: INote;
    messages?: IDeliveryPointMessage[];
    deliveryWindows?: ITimeWindow[];
    holidayWindows?: IDateWindow[];
    deposits?: IDepositItemInfo[];
    stopType?: IStopType;
    defaultRoute?: IRoute;
    dynamicHeader?: IDynamicHeaderCode;
    productWhiteLists?: IProductList[];
    productBlackLists?: IProductList[];
    deliveryPointSchedules?: IDeliveryPointSchedule[];
    formDPSchedules?: any[];
    deliveryPointPriceList?: IPriceList;
    generalPriceList?: IPriceList;
    enableSettings: boolean;
    settings: IDeliveryPointSetting[];
    appFileReference?: string;
    standingOrders?: IStandingOrder[];
    formStandingOrders?: any[];
    planogramItems?: IPlanogramItem[];
    dayBasedSequence?: IDeliveryPointDailySequence;
    useDayBasedSequence?: boolean;
    prospectingInfo?: IProspectingInfo;
    salesRepresentative?: IEmployee;
    rrpPriceList?: IPriceList;
}
export class DeliveryPoint extends BaseFranchiseEntity implements IDeliveryPoint {
    public id?: number;
    public active?: boolean;
    public accountName?: string;
    public accountNumber?: string;
    public sequence?: number;
    public typicalServiceTime?: number;
    public physicalKeyNumber?: string;
    public contactInfo?: IContactInfo;
    public notes?: INote;
    public messages?: IDeliveryPointMessage[];
    public deliveryWindows?: ITimeWindow[];
    public holidayWindows?: IDateWindow[];
    public deposits?: IDepositItemInfo[];
    public stopType?: IStopType;
    public defaultRoute?: IRoute;
    public dynamicHeader?: IDynamicHeaderCode;
    public productWhiteLists?: IProductList[];
    public productBlackLists?: IProductList[];
    public deliveryPointSchedules?: IDeliveryPointSchedule[];
    public enableSettings = false;
    public settings: IDeliveryPointSetting[];
    public appFileReference: string;
    public standingOrders: IStandingOrder[];
    public deliveryPointPriceList?: IPriceList;
    public generalPriceList?: IPriceList;
    public planogramItems?: IPlanogramItem[];
    public dayBasedSequence?: IDeliveryPointDailySequence;
    public useDayBasedSequence?: boolean;
    public prospectingInfo?: IProspectingInfo;
    public dpSalesRepresentative?: IEmployee;
    public vatRegNo?: string;
    public rrpPriceList?: IPriceList;

    static processArray(obj: IDeliveryPoint[]): IDeliveryPoint[] {
        if (obj) {
            const result = [];
            obj.forEach((o) => result.push(new DeliveryPoint(o)));
            return result;
        }
        return null;
    }

    constructor(deliveryPoint?) {
        super();
        if (deliveryPoint) {
            this.id = deliveryPoint.id;
            this.active = deliveryPoint.active;
            this.accountName = deliveryPoint.accountName;
            this.accountNumber = deliveryPoint.accountNumber;
            this.sequence = deliveryPoint.sequence;
            this.typicalServiceTime = deliveryPoint.typicalServiceTime;
            this.contactInfo = new ContactInfo(deliveryPoint.contactInfo);
            this.notes = new Note(deliveryPoint.notes);
            this.stopType = deliveryPoint.stopType;
            this.defaultRoute = deliveryPoint.defaultRoute;
            this.dynamicHeader = deliveryPoint.dynamicHeader;
            this.deliveryWindows = deliveryPoint.deliveryWindows || [];
            this.holidayWindows = deliveryPoint.holidayWindows || [];
            this.productWhiteLists = deliveryPoint.productWhiteLists || [];
            this.productBlackLists = deliveryPoint.productBlackLists || [];
            this.deliveryWindows = deliveryPoint.deliveryWindows ? TimeWindow.processArray(deliveryPoint.deliveryWindows) : [];
            this.holidayWindows = deliveryPoint.holidayWindows ? DateWindow.processArray(deliveryPoint.holidayWindows) : [];
            this.deposits = deliveryPoint.deposits || [];
            this.messages = deliveryPoint.messages || [];
            this.deliveryPointSchedules = deliveryPoint.deliveryPointSchedules || [];
            this.enableSettings = deliveryPoint.enableSettings;
            this.settings = deliveryPoint.settings;
            this.appFileReference = deliveryPoint.appFileReference;
            this.standingOrders = deliveryPoint.standingOrders;
            this.deliveryPointPriceList = deliveryPoint.deliveryPointPriceList;
            this.generalPriceList = deliveryPoint.generalPriceList;
            this.planogramItems = deliveryPoint.planogramItems || [];
            this.dayBasedSequence = deliveryPoint.dayBasedSequence;
            this.useDayBasedSequence = deliveryPoint.useDayBasedSequence;
            this.assignedFranchise = deliveryPoint.assignedFranchise;
            this.owningFranchise = deliveryPoint.owningFranchise;
            this.prospectingInfo = new ProspectingInfo(deliveryPoint.prospectingInfo);
            this.dpSalesRepresentative = deliveryPoint.dpSalesRepresentative;
            this.vatRegNo = deliveryPoint.vatRegNo;
            this.physicalKeyNumber = deliveryPoint.physicalKeyNumber;
            this.rrpPriceList = deliveryPoint.rrpPriceList;
        } else {
            this.contactInfo = new ContactInfo();
            this.prospectingInfo = new ProspectingInfo();
            this.notes = new Note();
            this.productWhiteLists = [];
            this.productBlackLists = [];
            this.deliveryWindows = [];
            this.holidayWindows = [];
            this.deposits = [];
            this.messages = [];
            this.deliveryPointSchedules = [];
            this.standingOrders = [];
            this.planogramItems = [];
        }
    }
    get discriminator(): any {
        return this.accountName;
    }
    clone(): DeliveryPoint {
        return new DeliveryPoint(this);
    }
}
