import {Customer} from 'app/blocks/model/customer.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICustomer} from 'app/blocks/model/customer.model';
import {SERVER_API_URL} from 'app/app.constants';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IDeliveryPoint, DeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class CustomerService extends EntityCrudService<ICustomer> {
    constructor(http: HttpClient) {
        super(http, Resource.CUSTOMERS, (obj?) => new Customer(obj));
    }

    createCustomer = (customerForm): any => {
        return this.http.post<any>(SERVER_API_URL + 'api/customers/quick-create', customerForm, {observe: 'response'}).toPromise();
    };

    setCustomersInactive = (customerIds): any => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/customers/inactive', customerIds, {
                observe: 'response'
            })
            .toPromise();
    };

    getInactiveCustomers = (fromDate: string | null): any => {
        const params: any = {
            fromDate: fromDate
        };
        return this.http
            .get(`${SERVER_API_URL}api/customers/no-transactions`, {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getDeliveryPoints = (id: number, showActive?: boolean): Promise<IDeliveryPoint[]> => {
        let params = new HttpParams();
        if (showActive !== null && showActive !== undefined) {
            params = params.append('showActive', showActive);
        }

        return this.http
            .get<IDeliveryPoint[]>(`${SERVER_API_URL}api/customers/${id}/delivery-points`, {params: params})
            .toPromise()
            .then((responseEntities) => responseEntities.map((responseEntity) => new DeliveryPoint(responseEntity)));
    };

    getDeliveryPointCount = (id: number): Promise<number> => {
        return this.http.get<number>(`${SERVER_API_URL}api/customers/${id}/delivery-points/count`).toPromise();
    };

    getInvoiceSettings = (id: number): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/customers/${id}/invoice-settings`).toPromise();
    };

    getRecentOrders = (customerId: number, numberPreviousOrders: number): Promise<any> => {
        const requestParams = createRequestOption({
            count: numberPreviousOrders
        });
        return this.http
            .get(`${SERVER_API_URL}api/customers/${customerId}/recent-orders`, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    };

    inviteCustomersToClientPortal = (customerIds: any): Promise<any> => {
        const requestParams = createRequestOption({customerIds: customerIds});
        return this.http
            .post(`${SERVER_API_URL}api/customers/invite-to-client-portal`, null, {observe: 'response', params: requestParams})
            .toPromise();
    };

    getCustomer = (customerId: any): Promise<any> => {
        return this.http
            .get(`${SERVER_API_URL}api/customers/${customerId}`, {
                observe: 'response'
            })
            .toPromise();
    };

    getBalance = (customerId: number): Promise<any> => {
        return this.http
            .get(`${SERVER_API_URL}api/customers/${customerId}/balance`, {
                observe: 'response'
            })
            .toPromise();
    };

    getCustomersForCustomerGroup = (pageNumber, size, customerGroupId): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('customerGroupId', customerGroupId);
        params = params.append('page', pageNumber);
        params = params.append('size', size);
        params = params.append('sort', 'name,asc');
        return this.http
            .get(SERVER_API_URL + 'api/customers', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    searchCustomersByText = (pageNumber, size, text): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('query', text);
        params = params.append('page', pageNumber);
        params = params.append('size', size);
        params = params.append('sort', 'name,asc');
        return this.http
            .get(SERVER_API_URL + 'api/_search/customers', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getCustomersForPriceList = (priceListId: number): Promise<any> => {
        return this.http
            .get(`${SERVER_API_URL}api/customers/price-list/${priceListId}`, {
                observe: 'response'
            })
            .toPromise();
    };

    getCustomersForProductList = (productListId: number, blackWhiteList: string): Promise<any> => {
        let params = new HttpParams();
        params = params.append('blackWhiteList', blackWhiteList);
        return this.http.get(`${SERVER_API_URL}api/customers/product-list/${productListId}`, {observe: 'response', params: params}).toPromise();
    };

    getCustomerStatement = (customerIds: number[], dates: any, onlyOpen: boolean, statementCode: any, skipZeroBalance: boolean): Promise<any> => {
        let requestParams = createRequestOption({
            dates: dates,
            customerIds: customerIds,
            onlyOpen: onlyOpen,
            skipZeroBalance: skipZeroBalance
        });
        if (statementCode != null) {
            requestParams = requestParams.set('statementCode', statementCode);
        }
        return this.http
            .get(`${SERVER_API_URL}api/customers/statement`, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    getCustomerStatementForRoutePlanner = (
        customerIds: number[],
        dates: any,
        onlyOpen: boolean,
        statementCode: any,
        skipZeroBalance: boolean
    ): Promise<any> => {
        let requestParams = createRequestOption({
            dates: dates,
            customerIds: customerIds,
            onlyOpen: onlyOpen,
            skipZeroBalance: skipZeroBalance
        });
        if (statementCode != null) {
            requestParams = requestParams.set('statementCode', statementCode);
        }
        return this.http
            .get(`${SERVER_API_URL}api/customers/statement/route-planner`, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    getCustomerStatementAsCSV = (customerIds: number[], dates: any, onlyOpen: boolean): Promise<any> => {
        const requestParams = createRequestOption({
            dates: dates,
            customerIds: customerIds,
            onlyOpen: onlyOpen
        });
        return this.http
            .get(`${SERVER_API_URL}api/customers/statement-csv`, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    emailCustomerStatement = (
        customerIds: number[],
        dates: any,
        onlyOpen: any,
        emailAll: any,
        emailSpecificStatementCodeCustomers: any,
        statementCode: any,
        skipZeroBalance: boolean
    ): Promise<any> => {
        let requestParams = createRequestOption({
            dates: dates,
            customerIds: customerIds,
            onlyOpen: onlyOpen,
            emailAll: Boolean(emailAll),
            emailSpecificStatementCodeCustomers: Boolean(emailSpecificStatementCodeCustomers),
            skipZeroBalance: skipZeroBalance
        });
        if (statementCode != null) {
            requestParams = requestParams.set('statementCode', statementCode);
        }
        return this.http
            .get(`${SERVER_API_URL}api/customers/email-statement`, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    emailCustomerStatementAsCSV = (customerIds: number[], dates: any, onlyOpen: any, emailAll: any): Promise<any> => {
        const requestParams = createRequestOption({
            dates: dates,
            customerIds: customerIds,
            onlyOpen: onlyOpen,
            emailAll: Boolean(emailAll)
        });
        return this.http
            .get(`${SERVER_API_URL}api/customers/email-statement-csv`, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    getCustomersForPromotion = (promotionId: number): Promise<any> => {
        return this.http
            .get(`${SERVER_API_URL}api/customers/promotion/${promotionId}`, {
                observe: 'response'
            })
            .toPromise();
    };

    getProductCodeMappingsForCustomer(id: number): Promise<any> {
        return this.http
            .get(`${SERVER_API_URL}api/customers/${id}/product-code-mapping`, {
                observe: 'response'
            })
            .toPromise();
    }

    assignCustomerTerritories = (customerIds: number[]): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/customers/assign-territories`;
        const requestParams = createRequestOption({
            customerIds: customerIds
        });
        return this.http.post<any>(REQUEST_URI, {}, {params: requestParams}).toPromise();
    };

    getDepositItems = (customerIds: number[], params: any): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/customers/deposit-items`;
        const requestParams = createRequestOption({
            ...params,
            customerIds: customerIds
        });
        return this.http
            .get(REQUEST_URI, {
                params: requestParams
            })
            .toPromise();
    };

    getAllDepositItems = (): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/customers/deposit-items/all`;
        return this.http.get(REQUEST_URI).toPromise();
    };

    getInactiveCustomerCSV = (fromDate: string): Promise<any> => {
        const requestParams = createRequestOption({
            fromDate: fromDate
        });

        return this.http
            .get(`${SERVER_API_URL}api/customers/inactive-customer-csv`, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };
}
