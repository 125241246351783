import {IAddress, Address} from 'app/blocks/model/address.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IContactInfo extends IBaseEntity {
    id?: number;
    name?: string;
    phone?: number;
    phoneExt?: number;
    workPhone?: number;
    workPhoneExt?: number;
    fax?: number;
    email?: string;
    address?: IAddress;
    validPhoneNumber?: boolean;
    whatsappPhoneNumber?: string;
}

export class ContactInfo extends BaseEntity implements IContactInfo {
    id: number;
    name: string;
    phone: number;
    phoneExt: number;
    workPhone: number;
    workPhoneExt: number;
    fax: number;
    email: string;
    address: IAddress;
    validPhoneNumber: boolean;
    whatsappPhoneNumber?: string;

    constructor(contactInfo?) {
        super();
        if (contactInfo) {
            this.id = contactInfo.id;
            this.name = contactInfo.name;
            this.phone = contactInfo.phone;
            this.phoneExt = contactInfo.phoneExt;
            this.workPhone = contactInfo.workPhone;
            this.workPhoneExt = contactInfo.workPhoneExt;
            this.fax = contactInfo.fax;
            this.email = contactInfo.email;
            this.address = contactInfo.address;
            this.validPhoneNumber = contactInfo.validPhoneNumber || false;
            this.whatsappPhoneNumber = contactInfo.whatsappPhoneNumber;
        } else {
            this.address = new Address();
            this.validPhoneNumber = true;
        }
    }

    clone(): ContactInfo {
        return new ContactInfo(this);
    }
}
