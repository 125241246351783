import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface INote extends IBaseEntity {
    id?: number;
    note?: any;
}

export class Note extends BaseEntity implements INote {
    public id?: number;
    public note?: any;
    constructor(note?) {
        super();
        if (note) {
            this.id = note.id;
            this.note = note.note;
        }
    }
    get discriminator(): any {
        return this.note;
    }
    clone(): Note {
        return new Note(this);
    }
}
