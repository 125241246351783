import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IDateWindow extends IBaseEntity {
    id?: number;
    holidayName?: string;
    startDate?: any;
    endDate?: any;
}

export class DateWindow extends BaseEntity implements IDateWindow {
    public id?: number;
    public holidayName?: string;
    public startDate?: any;
    public endDate?: any;

    static processArray(obj: DateWindow[]): DateWindow[] {
        if (obj) {
            const result = [];
            obj.forEach((o) => result.push(new DateWindow(o)));
            return result;
        }
        return null;
    }

    constructor(dateWindow?) {
        super();
        if (dateWindow) {
            this.id = dateWindow.id;
            this.holidayName = dateWindow.holidayName;
            this.startDate = dateWindow.startDate;
            this.endDate = dateWindow.endDate;
        }
    }

    get discriminator(): any {
        return this.holidayName;
    }

    clone(): DateWindow {
        return new DateWindow(this);
    }
}
